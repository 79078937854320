<template>
	<div class="container-fluid">
		<div class="row">
			<div class="col-12">
				<div class="box">
					<div>
						<label>{{ $t('client_area.contract_privacy.contract_validation') }}</label>

						<b-input-group prepend="FR">
							<b-form-input
								class="mr-1"
								v-model="privacy_policy_content"
								:placeholder="$t('client_area.contract_privacy.contract_validation_placeholder_fr')"
							></b-form-input>
						</b-input-group>
						
						<b-input-group prepend="EN" class="mt-2">
							<b-form-input
								class="mr-1"
								v-model="privacy_policy_content_en"
								:placeholder="$t('client_area.contract_privacy.contract_validation_placeholder_en')"
							></b-form-input>
						</b-input-group>
					</div>

					<div class="pt-4">
						<label>{{ $t('client_area.contract_privacy.cgv_link') }}</label>

						<b-input-group prepend="FR">
							<b-form-input
								class="mr-1"
								v-model="cgv_link"
								:placeholder="$t('client_area.contract_privacy.cgv_link_placeholder_fr')"
							></b-form-input>
						</b-input-group>

						<b-input-group prepend="EN" class="mt-2">
							<b-form-input
								class="mr-1"
								v-model="cgv_link_en"
								:placeholder="$t('client_area.contract_privacy.cgv_link_placeholder_en')"
							></b-form-input>
						</b-input-group>
					</div>

					<div class="pt-4">
						<label>{{ $t('client_area.contract_privacy.link_privacy_policy') }}</label>
			
						<b-input-group prepend="FR">
							<b-form-input
								class="mr-1"
								v-model="privacy_link"
								:placeholder="$t('client_area.contract_privacy.link_privacy_policy_placeholder_fr')"
							></b-form-input>
						</b-input-group>

						<b-input-group prepend="EN" class="mt-2">
							<b-form-input
								class="mr-1"
								v-model="privacy_link_en"
								:placeholder="$t('client_area.contract_privacy.link_privacy_policy_placeholder_en')"
							></b-form-input>
						</b-input-group>
					</div>

					<div class="pt-4">
						<label>{{ $t('client_area.contract_privacy.message_no_condition') }}</label>
			
						<b-input-group prepend="FR">
							<b-form-input
								class="mr-1"
								v-model="message_conditions"
								:placeholder="$t('client_area.contract_privacy.message_no_condition_placeholder_fr')"
							></b-form-input>
						</b-input-group>

						<b-input-group prepend="EN" class="mt-2">
							<b-form-input
								class="mr-1"
								v-model="message_conditions_en"
								:placeholder="$t('client_area.contract_privacy.message_no_condition_placeholder_en')"
							></b-form-input>
						</b-input-group>
					</div>

					<div class="pt-4">
						<label>{{ $t('client_area.contract_privacy.date_ouverture') }}</label>
			
						<div class="row">
							<div class="col-6">
								<div class="w-100">
									<b-input-group class="w-100" :prepend="getTrad('client_area.contract_privacy.debut')">
										<e-datepicker v-model="start_contract"></e-datepicker>
									</b-input-group>
								</div>
							</div>
							<div class="col-6">
								<div class="w-100">
									<b-input-group class="w-100" :prepend="getTrad('client_area.contract_privacy.fin')">
										<e-datepicker v-model="end_contract"></e-datepicker>
									</b-input-group>
								</div>
							</div>
						</div>


						<b-input-group :prepend="getTrad('client_area.contract_privacy.text_contract')" class="mt-2">
							<b-form-input
								class="mr-1"
								v-model="text_contract"
								:placeholder="$t('client_area.contract_privacy.text_contract')"
							></b-form-input>
						</b-input-group>
					</div>


					<div class="mt-4 col-12">
						<div class="form-group text-center">
							<button class="btn btn-primary" @click='save_privacy_settings'>
								{{$t("global.sauvegarder")}}
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>


<script type="text/javascript">
	import _defaults from 'lodash/defaults'
    import Contract from "@/mixins/Contract.js"

	export default {
		name: "CguCgv",
		mixins: [Contract],
		data () {
			return {
				privacy_policy_content: '',
				privacy_policy_content_en: '',
				cgv_link: '',
				cgv_link_en: '',
				privacy_link: '',
				privacy_link_en: '',
				message_conditions: '',
				message_conditions_en: '',
				start_contract: null,
				end_contract: null,
				text_contract: '',

			}
		},
		created() {
			this.preinit_component()
		},
		methods: {
			async preinit_component() {
				const ca_config = await this.getCaContractPrivacyPolicy()

				const temp = _defaults(ca_config, {
					privacy_policy_content: '',
					privacy_policy_content_en: '',
					cgv_link: '',
					cgv_link_en: '',
					privacy_link: '',
					privacy_link_en: '',
					message_conditions: '',
					message_conditions_en: '',
					start_contract: null,
					end_contract: null,
					text_contract: ''

				})

				Object.keys(temp).forEach(k => {
					this[k] = temp[k]
				})
			},

			save_privacy_settings() {
				this.saveCaContractPrivacyPolicy({
					privacy_policy_content	 : this.privacy_policy_content,
					privacy_policy_content_en: this.privacy_policy_content_en,
					cgv_link	: this.cgv_link,
					cgv_link_en : this.cgv_link_en,
					privacy_link: this.privacy_link,
					privacy_link_en: this.privacy_link_en,
					message_conditions: this.message_conditions,
					message_conditions_en: this.message_conditions_en,
					start_contract: this.start_contract,
					end_contract: this.end_contract,
					text_contract: this.text_contract

				}).then(() => {
					this.successToast()
				})
			}
		}
	}
</script>
